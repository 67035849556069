import { Injectable } from '@angular/core';
import { initializeApp, FirebaseApp } from 'firebase/app';
import { getDatabase, Database } from 'firebase/database';
import { FIREBASE_CONFIG } from 'src/environments/environment';

@Injectable({
  providedIn: 'root', // Singleton service
})
export class FirebaseService {
  private app: FirebaseApp;
  private database: Database;

  constructor() {
 

    // Initialize Firebase app and database instance
    this.app = initializeApp(FIREBASE_CONFIG);
    this.database = getDatabase(this.app);
  }

  // Expose the database instance
  getDatabaseInstance(): Database {
    return this.database;
  }
}
